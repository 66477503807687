import request from '@/utils/request'

//获取实习报告详情
export function getpracticereportinfo(params) {
    return request({
        url: '/portal/practice-report/get-info',
        method: 'GET',
        params
    })
}

//添加/编辑实习报告
export function addpracticereport(data) {
    return request({
        url: '/portal/practice-report/add',
        method: 'post',
        data
    })
}
 